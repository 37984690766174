@import '../../theme.scss';

.login-section {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F0F0F0;
  .login-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: fit-content;
  
    .title {
      text-align: center;
      font-size: $label-font-size;
      margin: 0;
    }
  
    .login-form {
      width: 100%;
      max-width: 400px;
      padding: 25px;
      .login-button {
        width: 100%;
      }
    }
  }
}