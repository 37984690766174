$title-font-size: 100px;
$subtitle-font-size: 80px;
$label-font-size: 40px;

$form-label-fs: 18px;

$small-screen-width: 1024px;
$tablet-screen-width: 768px;
$phone-screen-width: 480px;

@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond&family=Dancing+Script&family=Lobster&display=swap');
