@import '../../../theme.scss';

.recalls-section {
  width: 100%;
  background-color: #cfd4d9;
  padding: 25px 25px 50px 25px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  .main-container {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
      font-size: 30px;
      font-family: 'Lobster', cursive;
    }
    .line {
      width: 100%;
      border: 1px solid #000;
    }
    h3 {
      font-size: 28px;
      font-family: 'Lobster', cursive;
    }
    p {
      font-family: "Cormorant Garamond", serif;
      font-size: 30px;
      text-align: center;
    }
    span {
      font-size: 30px;
      margin-bottom: 50px;
      font-family: "Cormorant Garamond", serif;
      font-weight: bold;
    }
    .social-container {
      display: flex;
      justify-content: space-between;
      gap: 100px;
      .logo {
        width: 80px;
      }
    }
  }
}

@media screen and (max-width: $small-screen-width) {
  .recalls-section {
    padding: 4vw;
    .main-container {
      h2 {
        font-size: 3.2vw;
      }
      h3 {
        font-size: 3vw;
      }
      p {
        font-size: 3vw;
      }
      span {
        font-size: 3vw;
      }
      .social-container {
        gap: 10vw;
        .logo {
          width: 8vw;
        }
      } 
    } 
  } 
}


@media screen and (max-width: $tablet-screen-width) {  
  .recalls-section .main-container {
    h2 {
      font-size: 5vw;
    }
    h3 {
      font-size: 4vw;
    }
    p {
      font-size: 4vw;
    }
    span {
      font-size: 4vw;
    }
    .social-container .logo {
      width: 8vw;
    }
  } 
}


@media screen and (max-width: $phone-screen-width) {
  .recalls-section .main-container {
    h2 {
      font-size: 6vw;
    }
    h3 {
      font-size: 5vw;
    }
    p {
      font-size: 5vw;
    }
    span {
      font-size: 5vw;
    }
    .social-container .logo {
      width: 12vw;
    }
  }   
}