@import '../../../theme.scss';

.place-section {
    width: 100%;
    // height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: white;
    font-family: 'Lobster', cursive;
    box-sizing: border-box;
    padding: 25px;
    background-color: #f3f0ed;
    .device-type {
        position: fixed;
        top: 0;
        left: 0;
        background-color: #fff;
    }
    .main-container {
        display: flex;
        width: 100%;
        max-width: 1200px;
        justify-content: space-between;
        padding: 25px;
        gap: 25px;
        background-color: #e7d6d6;
        border-radius: 25px;
        box-sizing: border-box;
        .place-container {
            display: flex;
            flex-direction: column;
            flex: 1;
            .centered {
                display: flex;
                flex-direction: column;
                align-items: center;
                .title-container {
                    h2 {
                        font-size: 30px;
                        margin: 0;
                    }
                }
                .line {
                    width: 100%;
                }
                h3 {
                    font-size: 30px;
                    margin: 0 0 15px 0;
                    font-weight: normal;
                }
            }
            p {
                font-size: 28px;
                margin: 0;
                font-family: "Cormorant Garamond", serif;
            }
            p.time {
                margin-bottom: 15px;
            }
            a {
                text-decoration: none;
                color: #000;
                font-size: 25px;
                margin: 10px 0;
            }
            span {
                font-size: 20px;
                font-family: "Cormorant Garamond", serif;
                font-weight: bold;
            }
        }
        .photo {
            width: 550px;
            height: 350px;
            background-color: blueviolet;
            overflow: hidden;
            border-radius: 25px;
            img {
                width: 100%;
                height: auto;
            }
        }
    }
}

@media screen and (max-width: $small-screen-width) {
    .place-section {
        height: auto;
        .main-container {
            gap: 1vw;        
            .place-container {
                width: 50vw;
                .centered {
                    .title-container h2 {
                        font-size: 3.2vw;
                    }
                    h3 {
                        font-size: 3.2vw;
                        margin-bottom: 1vw;
                    }
                }
                p {
                    font-size: 2.5vw;
                }
                p.time {
                    margin-bottom: 1vw;
                }
                a {
                    font-size: 2.2vw;
                    margin: 1vw 0;
                }
                span {
                    font-size: 2vw;
                }
            } 
            .photo {
                width: 40vw;
                height: 25vw;
            }
        }
    } 
}
  
  
@media screen and (max-width: $tablet-screen-width) {
    .place-section {
        padding: 4vw;
        .main-container {
            padding: 3vw;
            .place-container {
                width: 100%;
                .centered {
                    .title-container h2 {
                        font-size: 5vw;
                    }
                    h3 {
                        font-size: 5vw;
                        margin-bottom: 5vw;
                    }
                } 
                p {
                    font-size: 4vw;
                }
                a {
                    font-size: 3.5vw;
                    margin: 2vw 0;
                }
                span {
                    font-size: 3vw;
                }
            }
            .photo {
                width: 100%;
                height: 55vw;
                margin-bottom: 5vw;
            }
        } 
    } 
}


@media screen and (max-width: $phone-screen-width) { 
    .place-section .main-container .place-container {
        .centered .title-container h2 {
            font-size: 6vw;
        }
        p {
            font-size: 5vw;
        }
        a {
            font-size: 4.5vw;
            margin: 2vw 0;
        }
        span {
            font-size: 4vw;
        }
    } 
}