@import '../../../theme.scss';

.schedule-section {
    background-image: url('../../../assets/img/marble_background.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    width: 100%;
    padding: 50px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .schedule-container {
        max-width: 1200px;
        width: 100%;
        padding: 25px;
        font-family: 'Lobster', cursive;
        hr {
            border: 1px solid #000;
            margin: 25px;
        }
        h2 {
            text-align: center;
            font-size: 30px;
            margin-top: 0;
        }
        .events-container {
            display: flex;
            justify-content: space-between;
            height: fit-content;
            .event-container {
                width: 100%;
                position: relative;
                .image-container {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    img {
                        width: 35%;
                    }
                }
                .title {
                    display: block;
                    text-align: center;
                    width: 100%;
                    height: 55px;
                    font-weight: bold;
                    font-size: 24px;
                }
                p {
                    text-align: justify;
                    margin-bottom: 40px;
                    font-size: 22px;
                    font-family: 'Cormorant Garamond', serif;
                }
                .time {
                    display: block;
                    text-align: center;
                    width: 100%;
                    position: absolute;
                    bottom: 0;
                    font-size: 22px;
                }
            }
            .divider {
                width: 1px;
                height: auto;
                margin: 0 10px;
                background-color: black;
            }
        }
    }
}

@media screen and (max-width: $small-screen-width) {
    .schedule-section {
        padding: 0;
        .schedule-container {
            padding: 5vw 4vw 7vw 4vw;
            h2 {
                font-size: 3.2vw;
            }
            .events-container .event-container {
                .title {
                    font-size: 2vw;
                    height: 5vw;
                }
                p {
                    font-size: 1.8vw;
                }
                .time {
                    font-size: 1.8vw;
                }
            } 
        }
    } 
}
  
  
@media screen and (max-width: $tablet-screen-width) {
    .schedule-section {
        padding: 5vw 0 7vw 0;
        .schedule-container {
            h2 {
                font-size: 5vw;
            }
            .events-container {
                flex-direction: column;
                gap: 2vw;
                .event-container {
                    display: flex;
                    gap: 10vw;
                    .image-container {
                        width: auto;
                        justify-content: flex-start;
                        padding-left: 5vw;
                        img {
                            width: 10vw;
                            height: auto;
                        }
                    }
                    .information-container {
                        width: 55vw;
                        .title {
                            height: auto;
                            font-size: 4vw;
                        }
                    }
                    p {
                        margin: 0;
                        font-size: 3.5vw;
                    }
                    .time {
                        position: initial;
                        font-size: 3.2vw;
                    }
                }
            }
        } 
    } 
}


@media screen and (max-width: $phone-screen-width) { 
    .schedule-section {
        background-attachment: unset;
        .schedule-container {
            padding: 5vw 1vw 7vw 1vw;
            hr {
              margin: 3vw 1vw;  
            }
            h2 {
                font-size: 6vw;
            }
            .events-container {
                flex-direction: column;
                gap: 2.5vw;
                .event-container {
                    display: flex;
                    gap: 10vw;
                    .image-container {
                        width: auto;
                        justify-content: flex-start;
                        padding-left: 5vw;
                        img {
                            width: 13vw;
                            height: auto;
                        }
                    }
                    .information-container {
                        width: 60vw;
                        .title {
                            height: auto;
                            font-size: 5vw;
                        }
                    }
                    p {
                        margin: 0;
                        font-size: 5vw;
                    }
                    .time {
                        position: initial;
                        font-size: 4.2vw;
                    }
                }
            }
        } 
    } 
}