@import '../../../theme.scss';

.parents-section {
  background-image: url('../../../assets/img/wall_flowers_background.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: 100%;
  width: 100%;
  background-attachment: fixed;
  display: flex;
  justify-content: center;
  align-items: center;

  .main-container {
    width: 100%;
    max-width: 1200px;
    padding: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
      text-align: center;
      font-size: 30px;
      font-family: 'Lobster', cursive;
    }

    h3 {
      text-align: center;
      font-weight: normal;
      font-size: 28px;
      font-family: "Cormorant Garamond", serif;
    }
    .parent-container {
      background-color: rgba(255, 255, 255, 0.358);
      padding: 0 15px;
      box-sizing: border-box;
      border-radius: 15px;
    }

    .two-sides-container {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 25px;
    }
  }
}


@media screen and (max-width: $small-screen-width) {
  .parents-section .main-container {    
    padding: 5vw 4vw 7vw 4vw;
    h2 {
      font-size: 3.2vw;
    }
    h3 {
      font-size: 3.2vw;
    }
  }
}


@media screen and (max-width: $tablet-screen-width) {
  .parents-section {
    padding: 0;
    .main-container {
      h2 {
        font-size: 5vw;
        margin: 0 0 2vw 0;
      }
      h3 {
        font-size: 5vw;
        margin: 0 0 2vw 0;
      }
      .two-sides-container {
        gap: 5vw;
        margin-top: 4vw;
        h2 {
          font-size: 4.3vw;
        }
        h3 {
          font-size: 4.1vw;
        }
      }
    }
  } 
}


@media screen and (max-width: $phone-screen-width) { 
  .parents-section{
    background-attachment: unset;
    .main-container {
      h2 {
        font-size: 5vw;
      }
      h3 {
        font-size: 5vw;
      }
      .parent-container {
        margin-top: 4vw;
        width: 100%;
      }
      .two-sides-container {
        flex-direction: column;
        margin: 0;
        gap: 1vw;
        h2 {
          font-size: 5vw;
        }
        h3 {
          font-size: 5vw;
        }
      }
    }
  }  
}