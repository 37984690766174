@import '../../../theme.scss';

.dess-code-section {
    background-image: url('../../../assets/img/fancy_background_2.webp');
    background-size: cover;
    background-position-x: center;
    background-position-y: center;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 25px;
    box-sizing: border-box;
    .main-container {
        width: 100%;
        max-width: 1200px;
        padding: 25px;
        background-color: #ffffff80;
        font-family: 'Lobster', cursive;
        border-radius: 25px;
        h2 {
            text-align: center;
            font-size: 30px;
            margin-top: 0;
        }
        hr {
            border: 1px solid #000;
            margin: 25px;
        }
        h3 {
            text-align: center;
            font-size: 28px;
        }
        .side-to-side {
            display: flex;
            justify-content: space-between;
            .dress-container {
                width: 100%;
                max-width: 400px;
                display: flex;
                flex-direction: column;
                align-items: center;
                font-family: "Cormorant Garamond", serif;
                font-weight: bold;
                .image-container {
                    width: 200px;
                    height: 300px;
                    display: flex;
                    justify-content: center;
                    margin-bottom: 25px;
                    img {
                        width: auto;
                        height: 100%;
                    }
                }
                span {
                    font-size: 35px;
                }
                p {
                    font-size: 30px;
                    text-align: justify;
                }
            }
        }
    }
}


@media screen and (max-width: $small-screen-width) {
    .dess-code-section {
        .main-container {
            padding: 5vw 4vw 7vw 4vw;
            h2 {
                font-size: 3.2vw;
            }
            h3 {
                font-size: 3vw;
            }
            .side-to-side {
                gap: 5vw;
                .dress-container {
                    span {
                        font-size: 2.8vw;
                    }
                    p {
                        font-size: 2.8vw;
                    }
                }
            }
        }
    }
}
  
  
@media screen and (max-width: $tablet-screen-width) {    
    .dess-code-section {
        padding: 4vw;
        .main-container {
            h2 {
                font-size: 5vw;
            }
            h3 {
                font-size: 4.5vw;
            }
            .side-to-side {
                gap: 5vw;
                .dress-container {
                    .image-container {
                        width: 40vw;
                        height: 40vw;
                    }
                    span {
                        font-size: 4.2vw;
                    }
                    p {
                        font-size: 4vw;
                    }
                }
            }
        }
    }
}


@media screen and (max-width: $phone-screen-width) { 
    .dess-code-section .main-container {        
        h2 {
            font-size: 6vw;
        }
        h3 {
            font-size: 5vw;
        }
        .side-to-side {
            flex-direction: column;
            .dress-container {
                .image-container {
                    width: 60vw;
                    height: 60vw;
                }
                span {
                    font-size: 5.5vw;
                }
                p {
                    font-size: 5vw;
                }
            } 
        }
    } 
}