@import '../../../theme.scss';

.story-section {
    background-color: #eaefed;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px;
    box-sizing: border-box;
    .main-container {
        width: 100%;
        max-width: 1200px;
        font-family: 'Lobster', cursive;
        h2 {
            text-align: center;
            font-size: 30px;
        }
        h3 {
            text-align: center;
            font-size: 35px;
            font-family: 'Dancing Script', cursive;
            font-weight: lighter;
        }
        .line {
            border: 1px solid #000;
        }
        .photos {
            margin-bottom: 25px;
            border-radius: 25px;
            overflow: hidden;
            .photo {
                background-position: center center;
                height: 500px;
                background-size: cover;
            }
            .p1 {
                background-image: url('../../../assets/img/carousel_1.webp');
            }
            .photo.p3 {
                background-image: url('../../../assets/img/carousel_3.webp');
            }
            .photo.p4 {
                background-position-y: 20%;
                background-image: url('../../../assets/img/carousel_4.webp');
            }
            .p5 {
                background-image: url('../../../assets/img/carousel_5.webp');
            }
            .photo.p6 {
                background-position-y: 80%;
                background-image: url('../../../assets/img/carousel_6.webp');
            }
            .p7 {
                background-image: url('../../../assets/img/carousel_7.webp');
            }
            .photo.p8 {
                background-image: url('../../../assets/img/carousel_8.webp');
            }
            .p9 {
                background-image: url('../../../assets/img/carousel_9.webp');
            }
            .p10 {
                background-image: url('../../../assets/img/carousel_10.webp');
            }
            .photo.p12 {
                background-position-y: 25%;
                background-image: url('../../../assets/img/carousel_12.webp');
            }
            .p13 {
                background-image: url('../../../assets/img/carousel_13.webp');
            }
            .photo.p14 {
                background-position-y: 75%;
                background-image: url('../../../assets/img/carousel_14.webp');
            }
            .photo.p15 {
                background-position-y: 60%;
                background-image: url('../../../assets/img/carousel_15.webp');
            }
        }
    }
}


@media screen and (max-width: $small-screen-width) {
    .story-section .main-container {
        h2 {
            font-size: 3.2vw;
        }
        h3 {
            font-size: 3.2vw;
        }
    } 
}
  
  
@media screen and (max-width: $tablet-screen-width) {
    .story-section {        
        padding: 4vw;
        .main-container {
            h2 {
                font-size: 5vw;
            }
            h3 {
                font-size: 4vw;
            }
            .photos .photo {
                height: 60vw;
            }
        } 
    } 
}


@media screen and (max-width: $phone-screen-width) {  
    .story-section {   
        .main-container {
            h2 {
                font-size: 6vw;
            }
            h3 {
                font-size: 5vw;
            }
            .photos .photo {
                height: 60vw;
            }
        } 
    } 
}