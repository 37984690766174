@import '../../theme.scss';

.welcome-modal {
  .ant-modal-content{
    background-color: transparent;    
    background-image: url('../../assets/img/envelope4.webp');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    width: 500px;
    height: 500px;
    margin: 0;
    font-family: 'Dancing Script', cursive;
    box-shadow: none;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    
    .ant-modal-body {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .welcome-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        span {
          font-size: 40px;
          text-align: center;
          margin: 0;
        }
        h3 {
          font-size: 40px;
          margin: 0 0 80px 0;
        }
        .enter-button {
          height: auto;
          font-family: 'Dancing Script', cursive;
        }
      }
    }
  }
}
@media screen and (max-width: $small-screen-width) {
  
}


@media screen and (max-width: $tablet-screen-width) {
  .welcome-modal .ant-modal-content {
    height: 70vw;
    width: 70vw;
    .ant-modal-body .welcome-container {
      span {
          font-size: 5.5vw;
      } 
      h3 {
        font-size: 6vw;
        margin-bottom: 10vw;
      }
    } 
  }
}


@media screen and (max-width: $phone-screen-width) {   
  .welcome-modal .ant-modal-content {
    height: 90vw;
    width: 90vw;
    .ant-modal-body .welcome-container {
      span {
          font-size: 7vw;
      } 
      h3 {
        font-size: 8vw;
        margin-bottom: 12vw;
      }
    } 
  }
}