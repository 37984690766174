@import '../../../theme.scss';

.gift-table-section {  
  width: 100%;
  background-color: #e2d3d0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px;
  box-sizing: border-box;
  .main-container {
    width: 100%;
    max-width: 1200px;
    .line {
      border: 1px solid #000;
    }
    h2 {
      text-align: center;
      font-family: 'Lobster', cursive;
      font-size: 30px;
    }
    h3 {
      text-align: center;
      font-family: 'Lobster', cursive;
      font-weight: normal;
      font-size: 28px;
    }
    .two-sides-container {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      margin-bottom: 25px;
      .side-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 400px;
        span {
          font-family: "Cormorant Garamond", serif;
          font-size: 25px;
          text-align: center;
          margin-bottom: 20px;
        }
        .amazon-card {
          width: 300px;
          height: 300px;
          box-shadow: -1px 1px 15px -1px rgba(0,0,0,0.75);
          -webkit-box-shadow: -1px 1px 15px -1px rgba(0,0,0,0.75);
          -moz-box-shadow: -1px 1px 15px -1px rgba(0,0,0,0.75);
          .ant-card-body {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            .amazon-logo {
              width: 200px;
            }
          }
          &:hover {
            box-shadow: -1px 1px 10px -1px rgba(0,0,0,0.75);
            -webkit-box-shadow: -1px 1px 10px -1px rgba(0,0,0,0.75);
            -moz-box-shadow: -1px 1px 10px -1px rgba(0,0,0,0.75);
          }
        }
        .bbva-card {
          width: 300px;
          height: 300px;
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: -1px 1px 15px -1px rgba(0,0,0,0.75);
          -webkit-box-shadow: -1px 1px 15px -1px rgba(0,0,0,0.75);
          -moz-box-shadow: -1px 1px 15px -1px rgba(0,0,0,0.75);
          .ant-card-body {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 100%;
            .bbva-logo {
              width: 200px;
            }
            .data-container {
              h4 {            
                font-family: "Cormorant Garamond", serif;
                font-size: 18px;
                width: 100%;
              }
            }
          }
          &:hover {
            box-shadow: -1px 1px 10px -1px rgba(0,0,0,0.75);
            -webkit-box-shadow: -1px 1px 10px -1px rgba(0,0,0,0.75);
            -moz-box-shadow: -1px 1px 10px -1px rgba(0,0,0,0.75);
          }
        }
      }
    }
  }
}

@media screen and (max-width: $small-screen-width) {
  .gift-table-section {
    padding: 4vw 4vw 7vw 4vw;
    .main-container {
      h2 {
        font-size: 3.2vw;
      }
      h3 {
        font-size: 3vw;
      }
      .two-sides-container .side-container {
        span {
          font-size: 2.8vw;
        }
        .amazon-card {
          width: 30vw;
          height: 30vw;
          .ant-card-body .amazon-logo {
            width: 90%;
          }
        }
        .bbva-card {
          width: 30vw;
          height: 30vw;
          .ant-card-body {
            padding: 2vw;
            .bbva-logo {
              width: 90%;
            }
            
            .data-container {
              h4 {            
                font-size: 2vw;
                width: 100%;
                margin: 0;
              }
            }
          } 
        }
      } 
    } 
  } 
}


@media screen and (max-width: $tablet-screen-width) {
  .gift-table-section {
    padding: 4vw;
    .main-container {
      h2 {
        font-size: 5vw;
      }
      h3 {
        font-size: 4vw;
      }
      .two-sides-container .side-container {
        span {
          font-size: 3vw;
        }
        .amazon-card {
          width: 35vw;
          height: 35vw;
          .ant-card-body .amazon-logo {
            width: 90%;
          }
        }
        .bbva-card {
          width: 35vw;
          height: 35vw;
          .ant-card-body {            
            .data-container {
              h4 {            
                font-size: 2.6vw;
              }
            }
          } 
        }
      } 
    } 
  } 
}


@media screen and (max-width: $phone-screen-width) { 
  .gift-table-section .main-container {
    h2 {
      font-size: 5vw;
    }
    h3 {
      font-size: 4.5vw;
    }
    .two-sides-container {
      flex-direction: column;
      .side-container {
        width: auto;
        span {
          font-size: 5vw;
        } 
        .amazon-card {
          width: 50vw;
          height: 50vw;
          .ant-card-body .amazon-logo {
            width: 90%;
          }
        }
        .bbva-card {
          width: 50vw;
          height: 50vw;
          .ant-card-body {            
            .data-container {
              h4 {            
                font-size: 3.6vw;
              }
            }
          } 
        }
      } 
    }
  } 
}