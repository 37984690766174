.sound-container {
  position: fixed;
  top: 50%;
  right: 10px;
  .sound-button {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
      font-size: 30px;
    }
  }
}