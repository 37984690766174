@import '../../../theme.scss';

.countdown-section {
    background-image: url('../../../assets/img/hero_1.webp');
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position-x: 30%;
    background-position-y: center;

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: 'Dancing Script', cursive;
        color: white;
        justify-content: space-between;
        height: 95%;
        .title {
            font-size: 140px;
            margin: 0;
        }
        .subtitle {
            font-size: $subtitle-font-size;
            margin: 0;
        }
        .count-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            background: #00000031;
            padding: 25px;
            border-radius: 25px;
            .remain-label {
                font-size: $label-font-size;
                margin: 0;
            }
            .countdown-container {
                display: flex;
                justify-content: space-between;
                gap: 80px;
                .count-column {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .count {
                        font-size: 100px;
                    }
                    .label {
                        font-size: 40px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $small-screen-width) {
    .countdown-section {
        background-position-x: 30%;
        .container {
            .title {
                font-size: 10vw;
            }    
        
            .subtitle {
                font-size: 8vw;
            }
            
            
            .count-wrapper {                
                .remain-label {
                    font-size: 4vw;
                }
                .countdown-container {
                    gap: 8vw;
                    .count-column {
                        .count {
                            font-size: 12vw;
                        }
                        .label {
                            font-size: 4vw;
                        }
                    }
                }
            }
        }
    } 
}
  
  
@media screen and (max-width: $tablet-screen-width) {
    .countdown-section {
        background-position-x: 35%;
        .container {
            height: 90%;
            justify-content: space-between;
        }
    }
}


@media screen and (max-width: $phone-screen-width) {    
    .countdown-section {
        background-position-x: 40%;
        .container {
            .title {
                font-size: 12vw;
                // margin: 0 0 40px 0;
            }    
        
            .subtitle {
                font-size: 10vw;
                // margin: 0 0 40px 0;
            }
            
            .remain-label {
                font-size: 6vw;
            }
        }
    }    
}