@import '../../../theme.scss';

.attendance-section {
  width: 100%;
  padding: 50px 0;
  background-color: #26323d;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  color: white;
  .side-to-side-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    padding: 25px;
    gap: 50px;
    .message-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      h2 {
        font-size: 30px;
        font-family: 'Lobster', cursive;
      }
      .line {
        width: 300px;
        border: 1px solid white;
      }
      p {
        text-align: center;
        font-family: "Cormorant Garamond", serif;
        font-size: 30px;
      }
    }
    .attendance-form-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      border: 1px solid white;
      padding: 25px;
      border-radius: 25px;
      h2 {
        text-align: center;
        font-size: 30px;
        font-family: 'Lobster', cursive;
      }
      .line {
        width: 100%;
        border: 1px solid white;
        margin-bottom: 25px;
      }
      .person-row {
        display: flex;
        margin-bottom: 15px;
        gap: 15px;
        .companion {
          font-size: 25px;
          font-family: "Cormorant Garamond", serif;
        }
        .delete-button {
          font-size: 28px;
          padding: 0;
          color: white;
          &:hover {
            background: none;
          }
        }
      }
      .radios {
        display: flex;
        flex-direction: column;
        padding: 10px;
        gap: 15px;
        margin-bottom: 15px;
        label {
          font-size: 25px;
          font-family: "Cormorant Garamond", serif;
          color: white;
          .ant-radio-inner {
            width: 25px;
            height: 25px;
          }
        }
      }
      .person-label {
        margin-bottom: 15px;
        font-size: 25px;
        font-family: "Cormorant Garamond", serif;
      }
      .main {
        margin-bottom: 15px;
        font-size: 25px;
        font-family: "Cormorant Garamond", serif;
      }
      .answer-button {
        height: 50px;
        font-size: 24px;
        font-family: 'Lobster', cursive;
      }
      .add-companion {
        font-size: 20px;
        font-family: 'Lobster', cursive;
        height: auto;
        margin-bottom: 15px;
      }
      .message {
        width: 100%;
        text-align: center;
        font-size: 24px;
        font-family: 'Lobster', cursive;
        font-weight: normal;
      }
      .submit-button {
        margin-bottom: 15px;
        font-size: 24px;
        height: auto;
        font-family: 'Lobster', cursive;
      }
    }
  }
}

@media screen and (max-width: $small-screen-width) {
  .attendance-section .side-to-side-container {
    gap: 5vw;
    .message-container {
      h2 {
        font-size: 3.2vw;
      }
      p {
        font-size: 3vw;
      }
    } 
    .attendance-form-container {
      padding: 2vw;
      h2 {
        font-size: 3.2vw;
      }
      .radios label {
        font-size: 2.5vw;
      } 
      .person-label {
        font-size: 2.5vw;
      }
      .main {
        font-size: 2.5vw;
      } 
      .submit-button  {
        font-size: 2.5vw;
      }
      .message {
        font-size: 2.1vw;
      }
      .add-companion {
        font-size: 2.2vw;
      }
      .person-row {
        .companion {
          font-size: 2.5vw;
        }
        .delete-button {
          font-size: 3vw;
        }
      } 
    } 
  } 
}


@media screen and (max-width: $tablet-screen-width) {
  .attendance-section {
    padding: 0 5vw;
    .side-to-side-container {
      flex-direction: column;
      gap: 2vw;
      .message-container {
        h2 {
          font-size: 5vw;
        }
        p {
          font-size: 4vw;
        }
      } 
      .attendance-form-container {
        padding: 5vw;
        h2 {
          font-size: 5vw;
        }
        .radios label {
          font-size: 4vw;
        }
        .person-label {
          font-size: 4vw;
        }           
        .main {
          font-size: 4vw;
        } 
        .submit-button  {
          font-size: 4vw;
        }
        .message {
          font-size: 4vw;
        }
        .add-companion {
          font-size: 3.5vw;
        }
        .person-row {
          .companion {
            font-size: 4vw;
          }
          .delete-button {
            font-size: 5vw;
          }
        } 
      } 
    }
  } 
}


@media screen and (max-width: $phone-screen-width) {
  .attendance-section .side-to-side-container {
    .message-container {
      h2 {
        font-size: 6vw;
      }
      p {
        font-size: 5vw;
      }
    } 
    .attendance-form-container {
      h2 {
        font-size: 6vw;
      }
      .radios label {
        font-size: 5vw;
      }
      .person-label {
        font-size: 5vw;
      }           
      .main {
        font-size: 5vw;
      } 
      .submit-button  {
        font-size: 5vw;
      }
      .message {
        font-size: 3.8vw;
      }
      .add-companion {
        font-size: 4.2vw;
      }
      .person-row {
        .companion {
          font-size: 5vw;
        }
        .delete-button {
          font-size: 5.5vw;
        }
      } 
    } 
  }
}