@import '../../../theme.scss';
.contact-section {
  width: 100%;
  padding: 5vh 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f3f0ed;
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
      margin: 0;
      font-family: 'Lobster', cursive;
      font-size: 30px;
    } 
    h3 {
      margin: 0;
      font-family: "Cormorant Garamond", serif;
      font-size: 28px;
    } 
    h4 {
      margin: 0;
      font-family: "Cormorant Garamond", serif;
      font-size: 25px;
    }
    .line {
      width: 300px;
    }
  }
}

@media screen and (max-width: $small-screen-width) {
  .contact-section .container {    
    h2 {
      font-size: 3.2vw;
    }
    h3 {
      font-size: 3vw;
    }
    h4 {
      font-size: 3vw;
    }
  }
}


@media screen and (max-width: $tablet-screen-width) {  
  .contact-section .container {    
    h2 {
      font-size: 5vw;
    }
    h3 {
      font-size: 4vw;
    }
    h4 {
      font-size: 4vw;
    }
  }
}


@media screen and (max-width: $phone-screen-width) { 
  .contact-section .container {    
    h2 {
      font-size: 6vw;
    }
    h3 {
      font-size: 5vw;
    }
    h5 {
      font-size: 4vw;
    }
  }
}