@import '../../../theme.scss';

.farewell-section {  
  background-image: url('../../../assets/img/hero_3.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  background-attachment: fixed;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Dancing Script', cursive;
  padding: 25px;
  box-sizing: border-box;
  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    .text-container {
      background-color: rgb(255 255 255 / 42%);
      text-align: center;
      padding: 25px;
      border-radius: 25px;
      h3 {
        font-weight: normal;
        font-size: 40px;
        margin-top: 0;
      }
      h4 {
        font-weight: normal;
        font-size: 40px;
        margin-top: 0;
      }
      h2 {
        font-size: 50px;
        margin-bottom: 0;
      }
    }
  }
}

@media screen and (max-width: $small-screen-width) {
  .farewell-section {
    padding: 4vw;
    .container .text-container {
      padding: 5vw;
      h3 {
        font-size: 4vw;
      }
      h4 {
        font-size: 4vw;
      }
      h2 {
        font-size: 5vw;
      }
    } 
  }
}


@media screen and (max-width: $tablet-screen-width) {  
  .farewell-section {
    .container .text-container {
      h3 {
        font-size: 5vw;
      }
      h4 {
        font-size: 5vw;
      }
      h2 {
        font-size: 6vw;
      }
    } 
  }
}


@media screen and (max-width: $phone-screen-width) { 
  .farewell-section {
    background-attachment: unset;
    .container .text-container {
      h3 {
        font-size: 7vw;
      }
      h4 {
        font-size: 7vw;
      }
      h2 {
        font-size: 9vw;
      }
    } 
  }
}