@import '../../../theme.scss';

.save-the-date-section {
    background-image: url('../../../assets/img/hero_2.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-attachment: fixed;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 25px;
    box-sizing: border-box;
    .container {        
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 1200px;
        gap: 60px;
        background-color: #ffffff4d;
        font-family: 'Dancing Script', cursive;
        padding: 25px;
        border-radius: 25px;
        .save-date-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            h2 {
                font-size: 100px;
                margin: 0;
            }
            h3 {
                font-size: 60px;
                margin: 0;
            }
        }
        h4 {
            font-size: 40px;
            text-align: center;
            margin: 0;
        }            
    }
}


@media screen and (max-width: $small-screen-width) {
    .save-the-date-section .container {
        padding: 2vw;
        height: 70%;
        justify-content: space-between;
        .save-date-wrapper{
            h2 {
                font-size: 10vw;
            }
    
            h3 {
                font-size: 6vw;
            }
        } 
        h4 {
            font-size: 4vw;
        }    
    } 
}
  
  
@media screen and (max-width: $tablet-screen-width) {
    .save-the-date-section {        
        padding: 4vw;
        .container {
            h2 {
                font-size: 12vw;
            }
            h4 {
                font-size: 5vw;
            }
        } 
    } 
}


@media screen and (max-width: $phone-screen-width) { 
    .save-the-date-section {        
        background-attachment: unset;
        .container {
            .save-date-wrapper{
                h2 {
                    font-size: 12vw;
                }
        
                h3 {
                    font-size: 6vw;
                }
            } 
            h4 {
                font-size: 6vw;
            }  
        }
    } 
}