.full-loader-section {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba($color: #ffffff, $alpha: 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  .ant-spin-dot-spin {
    width: 200px;
    height: 200px;
    .ant-spin-dot-item {
      width: 50px;
      height: 50px;
    }
  }
}